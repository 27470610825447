<template>
  <section class="products">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="top-banner"
    >
      <v-layout
        align-center
        fill-height
        justify-center
        style="position: relative; text-align: center;"
      >
        <v-flex style="position: absolute; bottom: 40px;" class="ctk-about-submenu">
          <template>
            <v-tabs
              v-model="tabSubMenu"
              light
              grow
            >
              <v-tab
                v-for="item in category"
                :key="item.id"
                :href="'#tab_'+item.id"
              >
                <template v-if="locale==='en'">
                  {{item.name_en}}
                </template>
                <template v-else>
                  {{item.name}}
                </template>
              </v-tab>
              <v-tab href="/product-znm">自粘膜</v-tab>
            </v-tabs>
          </template>
        </v-flex>
      </v-layout>
    </v-img>
    <v-container  grid-list-md text-center id="news_top" class="ctk-div">
      <v-tabs-items v-model="tabSubMenu">
        <v-tab-item :value="'tab_'+item.id" :key="'tab_'+item.id" v-for="(item, k) in category">
          <v-container
            fluid
            grid-list-sm
          >
            <v-layout wrap>
              <v-flex
                v-for="(cat, i) in category[k]._child"
                :key="i"
                xs12
                md4
                mb-12
                pa-2
              >
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    :elevation="hover ? 4 : 2"
                    class="mx-auto ctk-case-category-card"
                  >
                    <a :href="'/products/'+item.id+'/'+cat.id">
                      <v-img
                        :src="cat.img"
                        aspect-ratio="1"
                        class="ctk-case-category-img"
                      ></v-img>
                    </a>
                    <v-card-text style="text-align:left;min-height:110px;">
                      <h4 style="font-size:16px; color:#333;margin-bottom:10px;" :class="hover?'ctk-case-category-hover':''">
                        <template v-if="locale==='en'">
                          {{cat.name_en}}
                        </template>
                        <template v-else>
                          {{cat.name}}
                        </template>
                      </h4>
                      <span style="font-size:14px; color:#666;" :class="hover?'ctk-case-category-hover':''">
                        <template v-if="locale==='en'">
                          {{cat.desc_en}}
                        </template>
                        <template v-else>
                          {{cat.desc}}
                        </template>
                      </span>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 2,
    locale: null,
    category: null,
    tabSubMenu: null
  }),
  created () {
    document.querySelector('#menu_product').classList.add('v-btn--active')
    this.getCategory()
    this.locale = localStorage.getItem('locale')
  },
  mounted () {
    if (this.$route.params.pid) {
      this.tabSubMenu = 'tab_' + this.$route.params.pid
    } else {
      this.tabSubMenu = 'tab_1'
    }
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    },
    locale: {
      handler: function (val, oldVal) {
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    getCategory () {
      this.https.get('product_categorys').then(response => {
        if (response.code === 0) {
          this.category = response.data
        }
      })
    }
  }
}
</script>
